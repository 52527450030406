import React from 'react';
import { Navbar } from '../jra-navbar-container/jra-navbar-container';


interface HeaderProps {
 
}

export const JraHeaderContainer = ({
  
  ...props
}: HeaderProps) => {
 
  return (
    <div>
      <Navbar logoUrl='logo2.png' logoAltText='my alt text'/>
    </div>
  );
};
