import IconButton from "@mui/material/IconButton";
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from "@mui/material";

interface FullAutoCompleteSearchProps {

}

const StyledFullAutoCompleteSearch = styled(Grid)(
    ({ theme }) => `

    width: 100%;
    position: absolute;


    .search-autocomplete {
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        border-bottom: 1px solid rgba(200,200,200, 0.4);

        .search-autocomplete-button {
            border-radius: 0px;
            border: 1px solid rgba(200,200,200, 0.4);
            width: 70px;
        }

        .search-autocomplete-input {
            flex-grow: 3;

            input {
                height: 50px;
            }

            input:before {
                content: ''
            }
        }

        .search-autocomplete-clear-button {
            width: 60px;
            border-radius: 0px;
        }
}

.search-autocomplete-results {
    display: block;
    width: 100%;
    margin: 1rem;
    

    .search-autocomplete-results-group {
        display: block;

        .search-autocomplete-results-item {
            
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0.5rem;
           
          
            border-bottom: 1px solid rgba(200,200,200,0.4);
        
            &:hover {
                background-color: rgba(200,200,200,0.4);
                cursor: pointer;
            }
        }

        .MuiTypography-root {
            margin-bottom: 1rem;
            display: block;
        }
    }
    }
   
`
);

interface FullAutoCompleteSearchProps {
    onMenuClosed: (isOpen: boolean) => void;
}

export const FullAutoCompleteSearch = ({ onMenuClosed }: FullAutoCompleteSearchProps) => {


    return (
        <StyledFullAutoCompleteSearch container>
            <div className="search-autocomplete">
                <IconButton className="search-autocomplete-button">
                    <KeyboardArrowLeftOutlinedIcon onClick={() => onMenuClosed(false)} />
                </IconButton>
                <div className="search-autocomplete-input">
                    <TextField fullWidth id="standard-basic" variant="standard" placeholder="Rechercher" />



                </div>

                <IconButton className="search-autocomplete-clear-button">
                    <HighlightOffRoundedIcon onClick={() => { }} />
                </IconButton>
            </div>


            <div className="search-autocomplete-results">
                <div className="search-autocomplete-results-group">
                    <Typography variant="caption">Category: </Typography>
                    <div className="search-autocomplete-results-item">
                        <span>1</span>
                        <IconButton aria-label="" onClick={() => { }}>
                            <ArrowRightAltRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="search-autocomplete-results-item">
                        <span>2</span>
                        <IconButton aria-label="" onClick={() => { }}>
                            <ArrowRightAltRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="search-autocomplete-results-item">
                        <span>3</span>

                        <IconButton aria-label="" onClick={() => { }}>
                            <ArrowRightAltRoundedIcon />
                        </IconButton></div>
                </div>

            </div>
        </StyledFullAutoCompleteSearch>
    );
}