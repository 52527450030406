import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Typography } from "@mui/material";

import { MenuItem } from "../../types/menu-item-types";
import { RootMenu } from "./root-menu";

interface MenuProps {
  menuList: MenuItem[];
  isMenuOpen: boolean;
  menuClosed: any;
}

export const JraMenuContainer = ({
  menuList
}: MenuProps) => {

  const getMenuItem = (item: MenuItem, index: number, className = "menu-item") => {
    if (item.children?.length) {
      const { children } = item;
      return (
        <li key={index} className={className}>
          <Typography variant="overline">
            {item.label}
            <ArrowDropDownIcon className="arrow-down" />
            <ArrowDropUpIcon className="arrow-up" />
          </Typography>

          {
            <ul className={"menu sub-menu"}>
              {children.map((menu: MenuItem, subIndex: number) => {
                return getMenuItem(menu, subIndex, "sub-menu-item");
              })}
            </ul>
          }
        </li>
      );
    } else {
      return (
        <li className={className} key={index}>
          <Typography variant="overline">{item.label}</Typography>
        </li>
      );
    }
  };

  return (
    <RootMenu>
      <ul className={"menu"}>
        {menuList.map((menu: MenuItem, index: number) => getMenuItem(menu, index))}
      </ul>
    </RootMenu>
  );
};
