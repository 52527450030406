import { ThemeProvider } from "@mui/material";
import React from "react";
import { theme } from "./app/theme/default-theming";

import "./App.css";
import { Home } from "./app/pages/Home";
import { JraHeaderContainer } from "./app/common/jra-header-container/jrs-header-container";
import { Footer } from "./app/components/Footer";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
       
        <JraHeaderContainer />
        <Home />
        <Footer />
       
       
      </div>
    </ThemeProvider>
  );
}

export default App;
