import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from "@mui/material/styles";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';
import { MenuItem } from '../../types/menu-item-types';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface DrawerProps {
    anchor: Anchor;
    isOpen: boolean;
    onToggle: (isOpen: boolean) => void;
    menuItems: MenuItem[];
    currentView?: JSX.Element;
    onSubMenuOpen?: (subMenuItems: MenuItem[]) => void
};

const StyledDrawer = styled(SwipeableDrawer)(
    ({ theme }) => `
    
    .drawer-header {
        margin: 2rem;
    }

    .MuiListItemIcon-root {
        justify-content: flex-end;
    }

    .MuiButtonBase-root {
        margin-right: 0.6rem;
    }
    `
);

export default function Drawer({ anchor, isOpen, menuItems, onToggle }: DrawerProps) {

    const [currentSubMenu, setSubMenu] = React.useState<MenuItem>();

    const openSubMenu = (menuItem: MenuItem) => {
        setSubMenu(menuItem);
    }
    const removeSubMenu = () => {
        setSubMenu(undefined);
    }

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            onToggle(open);
        };

    const getMenuItem = (items: MenuItem[] | undefined) => {
        if (items !== undefined) {
            return items.map((menuItem, index) =>
                <>
                    <ListItem key={index}>
                        <ListItemButton>
                            <ListItemText primary={menuItem.label} />

                            {menuItem.children && menuItem.children.length ? <ListItemIcon onClick={() => openSubMenu(menuItem)}>
                                <KeyboardArrowRightRoundedIcon />
                            </ListItemIcon> : null}
                        </ListItemButton>
                    </ListItem>
                </>
            )
        }

        return null;
    }

    return (
        <div>

            <React.Fragment key={anchor}>
                <StyledDrawer
                    anchor={anchor}
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    disableSwipeToOpen={false}
                >
                    {
                        <Box
                            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280 }}
                            role="presentation"
                        >
                            {!currentSubMenu ? <List>
                                {
                                    getMenuItem(menuItems)
                                }
                            </List> :
                                <div>
                                    <div className={'drawer-header'}>
                                        <Typography variant="h6">
                                            <IconButton aria-label="go back" onClick={() => removeSubMenu()}>
                                                <KeyboardArrowLeftRoundedIcon />
                                            </IconButton>
                                            {currentSubMenu.label}
                                        </Typography>
                                    </div>
                                    <Divider />
                                    {getMenuItem(currentSubMenu?.children)}
                                </div>}
                        </Box>

                    }
                </StyledDrawer>
            </React.Fragment>

        </div>
    );
}