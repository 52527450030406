import React from 'react';


interface HomeProps {
 
}

export const Home = ({
  
  ...props
}: HomeProps) => {
 
  return (
    <div></div>
  );
};
