import { styled } from "@mui/material/styles";

export const RootMenu = styled("div")(
    ({ theme }) => `
    ${theme.breakpoints.down("md")} {
      & ul.menu {
        display: none;
      }
    }
  
  
    ${theme.breakpoints.up("md")} {
      width: 100%;
      border-top: 1px solid rgb(200,200,200);
      border-bottom: 1px solid rgb(200,200,200);
      list-style: none;
    
  
      & .mobile-menu {
        display: none;
      }
  
      & ul {
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
     
    
    
      & li.menu-item, li.sub-menu-item {
        list-style: none;
      }
      
      & .menu-item > span {
        border-bottom: 2px solid #fff;
        text-transform: uppercase;
        text-align: center;
        justify-content: center;
      }
    
      & .menu-item span {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 100px;
        font-weight: bold;
        height: 50px;
      }
    
      .menu-item > span:hover {
        cursor: pointer;
        border-bottom: 2px solid rgb(200,0,200)
      }
    
      & .sub-menu {
        margin-top: 2px;
        min-width: 200px;
        display: none;
        position: absolute;
        padding-inline-start: 0px;
        box-shadow: 0px 1px 1px 3px  rgba(212,212,212,0.37);
        -webkit-box-shadow: 0px 1px 1px 3px  rgba(212,212,212,0.37);
        -moz-box-shadow: 0px 1px 1px 3px  rgba(212,212,212,0.37);
      }
    
      & .sub-menu-item {
        align-items: flex-start;
        &:hover {
          cursor: pointer;
         background-color: rgba(200,200,200,0.4);
        }
      }
     
      & .sub-menu active {
        display: block;
      }
    
      & .menu-item .arrow-up {
        display: none;
      }
    
      & .menu-item .arrow-down {
        display: block;
      }
    
      & .menu-item:hover .arrow-up {
        display: block;
      }
    
      & .menu-item:hover .arrow-down {
        display: none;
      }
    
      & .menu-item:hover .sub-menu {display: block;}
      
    }
   
  `
  );
  