import React from 'react';


interface FooterProps {
 
}

export const Footer = ({
  
  ...props
}: FooterProps) => {
 
  return (
    <div></div>
  );
};
