import ListIcon from "@mui/icons-material/List";
import LoginIcon from "@mui/icons-material/Login";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { AutoCompleteSearch } from "../jra-autocomplete-search/jra-auto-complete-search";
import Drawer from "../drawer/drawer";
import { JraMenuContainer } from "../jra-menu-container/jra-menu-container";

const TopNavbar = styled(Grid)(
  ({ theme }) => `
  padding: 10px;
  max-height: 100px;
  border-bottom: 1px solid rgb(244,244,244)
 
`
);

const MobileMenuButton = styled(IconButton)(
  ({ theme }) => `

  ${theme.breakpoints.up("md")} {
    display: none;
  }`
);

const GridWrapper = styled(Grid)(
  ({ theme }) => `

  align-items: center;
  display: flex;
  flex-direction: row;
 

  ${theme.breakpoints.up("md")} {
    justify-content: space-between;
  }

  ${theme.breakpoints.up("md")} {
    justify-content: center;
  }

  & .button-country-chooser img {
    width: 30px;
  }

  & .logo {
    width: 80px;
    height: auto;
  }

  & .app-name {
    font-weight: bold;
    margin-left: 10px;
  }

  & .button-country-chooser, .button-auth , .button-cart {
    height: 35px;
    margin: 5px;
  }

  & .button-auth > .button-auth-text {
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }
  `
);
interface NavbarProps {
  logoUrl: string;
  logoAltText: string;
}

export const Navbar = ({ logoUrl, logoAltText, ...props }: NavbarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const menuClosed = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >

      <>

        <TopNavbar container item>
          <GridWrapper item xs={5} md={4}>
            <img className={"logo"} src={logoUrl} alt={logoAltText} />

            <Box component="span" className="app-name">
              JRA Game Store
            </Box>
          </GridWrapper>

          <GridWrapper item xs={1} md={4}>
            <AutoCompleteSearch />
          </GridWrapper>

          <GridWrapper item xs={5} md={4}>
            <Button
              variant="outlined"
              className="button-country-chooser"
              startIcon={<img src={"assets/flags/fr.svg"}  alt={"country"}/>}
            >
              FR
            </Button>

            <Button
              variant="outlined"
              className="button-auth"
              startIcon={<LoginIcon />}
            >
              <span className="button-auth-text">Connexion</span>
            </Button>

            <Button
              variant="outlined"
              className="button-cart"
              startIcon={<ShoppingCartIcon />}
            >
              0
            </Button>
          </GridWrapper>

          <GridWrapper item xs={1}>
            <MobileMenuButton
              aria-label="open-menu"
              color="primary"
              onClick={() => setIsMenuOpen(true)}
            >
              <ListIcon />
            </MobileMenuButton>
          </GridWrapper>
        </TopNavbar>


        <Grid container>
          <JraMenuContainer
            isMenuOpen={isMenuOpen}
            menuClosed={menuClosed}
            menuList={menuList}
          />
        </Grid>

        <Drawer isOpen={isMenuOpen} anchor={'right'} onToggle={menuClosed} menuItems={menuList} />
        
      </> </Grid>


  );
};

const menuList = [
  {
    label: "Cartes cadeaux",
    link: "#",
    active: false,
    children: [
      {
        label: "Apple Gift Card",
        link: "",
      },
      {
        label: "Blizziard",
        link: "",
      },
    ],
  },
  {
    label: "Credis In-Game",
    link: "",
    active: false,
    children: [],
  },
  {
    label: "BLOG",
    link: "",
    active: false,
    children: [
      {
        label: "Apple Gift Card",
        link: "",
      },
      {
        label: "Apple Gift Card",
        link: "",
      },
    ],
  },
  {
    label: "A la Une",
    link: "",
    active: false,
    children: [
      {
        label: "Apple Gift Card",
        link: "",
      },
      {
        label: "Apple Gift Card",
        link: "",
      },
    ],
  },
];
